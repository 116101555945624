.homepage {
  flex: 1;
  position: relative;
}

.slide-show {
  width: 100%;
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0px;
  bottom: 0px;
}

.slide-show .slick-slider {
  width: 100%;
  height: 100%;
}

.homepage .slick-slide {
  background-size: cover !important;
}

.homepage .slick-dots {
  bottom: 50px !important;
}

.homepage .slick-dots button::before {
  content: "." !important;
  top: -20px
}

.homepage .splash {
  font-family: "Nunito Sans";
  font-size: 80px;
  letter-spacing: 0.5;
  font-weight: bold;
  margin: 0;
  color: rgba(255, 255, 255, 0.4);
  line-height: 0.95;
  width: 100vw;
  transition: all 0.3s linear;
  position: relative;
  padding-top: 32vh;
}

.homepage .slogan {
  text-align: left;
  font-size: 80px !important;
  line-height: 1.05em;
  padding: 15px;
  letter-spacing: 0.5;
  font-weight: medium;
  margin: 0;
  margin-bottom: 40px;
  color: rgba(12, 17, 43);
  color: white;
  transition: all 0.3s ease;
  display: block !important;
  max-width: 850px;
}

@media (max-width: 850px) {
  .homepage .splash {
    padding-top: 30vh;
  }
  .homepage .splash .slogan {
    font-size: 36px !important;
  }
  .slogan {
    font-size: 36px;
    margin-bottom: 20px;
  }
}
