.toggle-nav {
  position: fixed;
  right: 5px;
  top: 15px;
  float: right;
  text-align: right;
  padding: 0px 25px;
  z-index: 105;
  cursor: pointer;
}

.toggle-nav svg {
  color: #3F51B5;
}

.toggle-nav:hover svg {
  color: #FFC30D;
}

.dropdown-container {
  float: right;
  text-align: right;
  list-style: none;
  display: flex;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  margin: 0;
  overflow-x: auto;
}

.dropdown-nav {
  position: absolute;
  display: block;
  z-index: 101;
  top: 70px;
  width: 100vw;
  height: calc(100vh - 70px);
}

.dropdown-nav-content {
  position: absolute;
  display: block;
  background-color: #3F51B5;
  min-width: 200px;
  width: 100vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 101;
  text-align: left;
  height: 100%;
}

.dropdown-content .nav-item {
  text-align: left;
}

.dropdown-nav-content .nav-item {
   color: #fff;
   font-size: 22px;
   line-height: 27px;
    padding: 15px 30px;
    width: 100%;
  margin: 0
 }

.dropdown-nav-content .nav-item > a {
  color: #fff !important;
  font-size: 22px;
  line-height: 27px;
}

.dropdown-nav-content .nav-item .ehr-action {
  padding: 0;
}

.dropdown-nav-content .nav-item .ehr-action:hover {
  background-color: transparent !important;
}

.dropdown-nav-content .nav-item select,  .dropdown-nav-content .nav-item select:focus {
  font-size: 20px  !important;
  border: none;
  outline: none;
  background-color: transparent;
}

.dropdown-nav-content .nav-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-bottom: none;
}

.dropdown-nav-content .nav-item a:hover {
  color: #fff;
  text-decoration: none !important;
  border-bottom: none !important;
}

.dropdown-nav-content #language-select {
  color: #fff !important;
}
