.nav-container {
  list-style: none;
  display: flex;
  flex: 1;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0;
  transition: all 0.2s linear;
  visibility: visible;
  justify-content: flex-end;
  align-items: center;
}

.nav-container:hover {
  visibility: visible !important;
  top: 25px !important;
}

.nav-container.hidden {
  visibility: hidden;
  top: -50px;
}

.nav-container.visibile {
  visibility: visible;
  top: 25px;
}

.nav-item {
  padding: 0;
  margin: 0 16px;
  font-size: 0.9em;
  box-sizing: border-box;
  flex-shrink: 0;
  color: #3F51B5;
}

.nav-item > a {
  text-decoration: none;
}

.nav-item > a:hover {
  color: #FFC30D;
  display: inline-block;
  font-weight: 800;
  border-bottom: 2pt solid #FFC30D !important;
}

.nav-item > a:active, .nav-item.selected > a {
  border-bottom: 2pt solid #3F51B5;
  font-weight: 800;
  border-bottom: 2pt solid #3F51B5;
}

.nav-item > select {
  outline: none;
  border: none;
  font-size: 14px;
  color: #3F51B5;
}
