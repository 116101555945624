
html {
    font-family: 'Nunito Sans';
    height: 100%;
}

body {
  min-height: 100%;
  font-family: 'Nunito Sans';
}

#root {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.App {
  height: 100%;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


.dropdown-menu {
  min-width: 0px !important;
}

#language-select {
  background-color: transparent !important;
  border: none !important;
  color: #3F51B5;
  padding: 0 !important;
}

.dropdown-menu .dropdown-item,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:visited
{
  color: #3F51B5 !important;
  background-color: #fff !important;
  opacity: 1;
}

.dropdown-item:hover {
  background-color: rgba(63, 81, 181, 0.3) !important;
}

.dropdown-menu .dropdown-item.active {
  background-color: transparent !important;
  font-weight: bold;
}

.go-to-appstore {
  font-size: 22px !important;
  line-height: 36px !important;
}

.preloader {
  height: 0px;
  width: 0px;
  display: none;
}

._loading_overlay_content {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  transition: all 3s linear;
}

._loading_overlay_spinner {
  height: 100vh;
}


.slick-slide > div {
  width: 100%;
  height: 100%;
}

.content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

.content-wrapper::after {
  background-color: #fff;
  color: #FFC30D;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  content: '© 2021 Kailona.';
  padding: 10px;
  font-weight: bold;
  opacity: 0.8;
}

.bg-name {
  color: #3F51B5 !important;
  font-size: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.Dignity-bg {
    background-image: url('/backgrounds/kailona-dignity.jpg');
}

.Trust-bg {
    background-image: url('/backgrounds/kailona-trust.jpg');
}

.Self-Determination-bg {
    background-image: url('/backgrounds/kailona-self-determination.jpg');
}

.Healing-bg {
    background-image: url('/backgrounds/kailona-healing.jpg');
}

.About-bg, .Press-bg {
    background-image: url('/backgrounds/kailona-about.jpg');
}

.press-release {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  flex-direction: column;
}

.press-release-item {
  padding: 5px;
  flex: 1;
}

.press-release-item-title {
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none !important;
  cursor: pointer;
}

.press-release-item-title:hover {
  text-decoration: underline !important;
}

.press-release-item-source {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.8;
}

.content-wrapper > img {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
}

.text-content {
  margin: auto;
  position: absolute;
  left: 0;
  padding: 40px 30px 40px 30px;
  background-color: #FFC30D;
  width: 50%;
  opacity: 0.9;
  text-align: left;
}

.text-content > h1 {
  font-size: 22px;
  line-height: 27px;
  color: #fff;
  margin: 0;
  word-wrap: break-word;
  width: 98%;
}

.text-content p {
  word-wrap: break-word;
  padding: 0 10px;
  text-align: left;
}

.text-content .body-lead {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #3F51B5;
  width: 94%;
}

.text-content .body-lead a {
  color: #3F51B5;
  font-weight: 500;
  text-decoration: underline;
}

.text-content .body-lead hr {
  border: 1px solid #fff;
  opacity: 0.5;
  margin-top: 30px;
  margin-bottom: 35px
}

.text-content .body-lead .sponsors {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 30px;
}

.text-content .body-lead .sponsors a:hover {
  opacity: 1;
}

.text-content .body-lead .sponsors a {
  margin: 0 10px;
  width: 18%;
}

.sponsor-acanio {
  width: 26% !important;
}

.text-content .body-lead .sponsors a img {
  width: 100%;
}

.text-content .body-lead .sponsors a.sponsor-prototype-fund img {
  width: 100%;
  max-height: 50px;
}

.text-content.lg {
  width: 100%;
}

.page {
  flex: 1;
  position: relative;
  overflow: hidden;

}

.page .splash {
  margin-top: 0px !important;
}

.mobile-splash {
  display: none !important;
}

.page .slick-slider {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.title {
  width: 75%;
  font-size: 75px;
  letter-spacing: 0.5;
  font-weight: medium;
  margin: 0;
  margin-bottom: 40px;
  color: white;
  line-height: 0.95;
}

.carousel .slide {
  background: none !important;
}

.carousel-status {
  display: none !important;
}

ul.slider {
  margin-bottom: 20px !important;
}

ul.control-dots li,
ul.control-dots li:active,
ul.control-dots li:focus
{
  background: #fff !important;
  outline: none !important;
  box-shadow: none !important;
}

ul.control-dots li.selected {
  opacity: 0.6 !important;
  border: 2px solid #fff;
  background-color: #FFC30D !important;
}


/* Add responsive style */
@media screen and (max-width: 768px) {
  .text-content {
    overflow: hidden;
  }

  .text-content  h1 {
    font-size: 22px;
    line-height: 25px;
  }
  .text-content .body-lead .sponsors {
    flex-wrap: wrap;
  }

  .text-content .body-lead .sponsors a {
    width: 30%;
    margin-bottom: 15px;
  }

  .sponsor-medken {
    width: 24% !important;
  }

  .sponsor-acanio {
    width: 36% !important;
  }

  .sponsor-toca {
    width: 25% !important;
  }

  .go-to-appstore-content {
    left: 0 !important;
    right: 0 !important;
  }

  .mobile-content {
    width: 100%;
  }
}

@media screen and (min-width: 1281px) {
  .text-content > h1 {
    font-size: 46px;
    line-height: 54px;
    margin-bottom: 30px;
  }

  .text-content .body-lead .sponsors a {
    width: 10%;
  }

  .sponsor-medken {
    width: 9% !important;
  }

  .sponsor-acanio {
    width: 14% !important;
  }

  .text-content .body-lead .sponsors a.sponsor-prototype-fund {
    width: 5% !important;
  }
}

.legal-notice {
  font-size: 12px;
  line-height: 14px;
  margin-top: 30px;
}

.legal-notice > h3 {
  font-size: 14px;
  line-height: 16px;
  font-weight: bold
}
