.header {
  width: 150px;
  max-width: 100%;
  box-sizing: border-box;
  padding: 0px 24px;
  color: #fff;
  justify-content: space-around;
  height: 100%;
  transition: all 0.2s linear;
}

.header:hover .nav-container {
  visibility: visible !important;
  top: 25px !important;
}

.logo {
  margin: 0;
  font-size: 20pt;
  margin-bottom: 16px;
  font-weight: bold;
}

.dark.logo {
  color: black;
}

.ehr-action{
  color: #fff !important;
  background-color: #3F51B5;
  border-radius: 30px;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  min-width: 150px;
}

.ehr-action:hover {
  color: #FFC30D !important;
  font-weight: 800;
  background-color: #3F51B5 !important;
  text-decoration: none;
  opacity: 1;
}
