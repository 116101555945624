
a,
a:visited {
  color: inherit;
  text-decoration: none;
}

.nav-item a {
  transition: all 0.2s linear;
}

a:hover {
  opacity: 0.7;
}

p {
  color: white;
  font-size: 1.2rem;
  line-height: 1.1rem;
}

.slick-slider {
  height: calc(85vh - 80px);
  width: calc(100vw - 80px);
  outline: none !important;
}

.slick-list.draggable {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text !important;
}

.slick-slider div {
  outline: none !important;
}

.slick-list,
.slick-track {
  height: 100%;
  color: white;
  outline: none !important;
}

.slick-arrow {
  display: none !important;
}

.slick-dots {
  position: fixed !important;
  bottom: 30px !important;
  color: white;
  width: 100% !important;
  text-align: center
}

.slick-dots button::before {
  content: "-" !important;
  font-size: 56pt !important;
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  min-width: 160px;
  max-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 15px 5px;
  right: 10px;
  z-index: 200;
  background: rgba(255, 255, 0, 0.9);
  opacity: 100;
  overflow: auto;
}

.dropdown-content .nav-item {
  color: #000;
  height: 25px;
}

.dropdown-content .link {
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
  opacity: 100;
}

/** Main **/
.main {
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
  padding-top: 80px;
  min-height: calc(100vh - 80px);
  display: flex;
  max-width: 100%;
  background: linear-gradient(170deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  column-count: 2;
  break-inside: avoid;
}

.main.post {
  background: white;
}

.slick-dots {
  color: white;
}

.slick-dots button::before {
  color: white;
}

@media (max-width: 850px) {
  .slick-slider {
    width: 85vw;
  }
}

@media (max-width: 400px) {
  .slick-dots button::before {
    font-size: 36pt !important;
  }
}

.slick-active button::before {
  color: white !important;
}

.main .post h1,
p {
  color: #444444;
}
.main .post .slick-dots {
  color: #FFC30D;
}

.main .post .slick-dots button::before {
  content: "-" !important;
  font-size: 46pt !important;
  color: black !important;
}

@media (max-width: 400px) {
  .main .post .slick-dots button::before {
    font-size: 36pt !important;
  }
}
